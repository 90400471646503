/**
 * @module breakpoint
 * @description Provide functions to check current window size.
 */

//  Breakpoints in pixels
const smallBreakpoint = 767;
const largeBreakpoint = 1056;

/**
 * Get current window width in px
 * @private
 * @returns {number}
 */
export const getWindowWidth = () =>
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

/**
 * Get current window height in px
 * @private
 * @returns {number}
 */
export const getWindowHeight = () =>
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

/**
 * Determine whether current window width is small
 * @public
 * @returns {boolean}
 */
export const isSmallScreenOnly = () => getWindowWidth() <= smallBreakpoint;

/**
 * Determine whether current window width is medium and up
 * @public
 * @returns {boolean}
 */
export const isMediumScreenAndUp = () => getWindowWidth() > smallBreakpoint;

/**
 * Determine whether current window width is medium but not more than
 * large
 * @public
 * @returns {boolean}
 */
export const isMediumScreenOnly = () => getWindowWidth() > smallBreakpoint && getWindowWidth() < largeBreakpoint;

/**
 * Determine whether current window width is large and up
 * @public
 * @returns {boolean}
 */
export const isLargeScreenAndUp = () => getWindowWidth() >= largeBreakpoint;
